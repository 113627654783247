import React from "react"
import SEO from "../seo"
import styled from "styled-components"
import { SiteTopButton } from "../PC/atoms/Button"
import { QuandoLogoSVG } from "../Images/svg"
import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"
import { MobileSiteTopButton } from "../mobile/atoms/MobileButton"


const Template = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    height: auto;
    width: 100%;
`

const NotFoundOuter = styled.div`
    width: 100%;
    height: 530px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const NotFoundInner = styled.div`
    width: 80%;
    height: 270px;
    display: flex;
    justify-content: center;
`

const NotFoundLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`

const NotFoundRight = styled.div`
    width: 20%;
`

const NotFoundTitle = styled.h2`
    font-size: 72px;
    line-height: 72px;
    letter-spacing: 0.15em;
    margin-bottom: 15px;
`

const NotFound = styled.h3`
    font-size:48px;
    line-height:48px;
    margin-bottom: 48px;
`

const NotFoundExplanation = styled.p``

const MobileNotFoundOuter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MobileNotFoundInner = styled.div`
    width: 80%;
    margin: 48px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const MobileNotFoundTitle = styled.h2`
    font-size: 44px;
    line-height: 44px;
    letter-spacing: 4px;
    margin-bottom: 16px;
`

const MobileNotFound = styled.h3`
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 2.4px;
    margin-bottom: 18px;
`

const MobileNotFoundExplanation = styled.p`
    font-size: 12px;
    line-height: 17px;
`

const NotFoundTemplate = () => {

    const breakPoints = useBreakpoint()

    return (
        <Template>
            <SEO title="404 Not Found" />
            {breakPoints.pc
                ?
                    <NotFoundOuter>
                        <NotFoundInner>
                            <NotFoundLeft>
                                <NotFoundTitle>404</NotFoundTitle>
                                <NotFound>NOT FOUND</NotFound>
                                <NotFoundExplanation>お探しのページは見つかりませんでした</NotFoundExplanation>
                                <SiteTopButton linkto="/"/>
                            </NotFoundLeft>
                            <NotFoundRight>
                                <QuandoLogoSVG />
                            </NotFoundRight>
                        </NotFoundInner>
                    </NotFoundOuter>
                :
                    null
            }
            {breakPoints.mobile
                ?
                    <MobileNotFoundOuter>
                        <MobileNotFoundInner>
                            <MobileNotFoundTitle>404</MobileNotFoundTitle>
                            <MobileNotFound>NOT FOUND</MobileNotFound>
                            <MobileNotFoundExplanation>お探しのページは見つかりませんでした</MobileNotFoundExplanation>
                            <MobileSiteTopButton linkto="/" />
                        </MobileNotFoundInner>
                    </MobileNotFoundOuter>
                :
                    null
            }
        </Template>
    )
}

export default NotFoundTemplate